.pricing-section-4 {
    isolation: isolate;
    .pricing-section-shape {
        position: absolute;
        z-index: -1;
        &-left {
            inset-block-start: 0;
            inset-inline-start: 0;
        }
        &-right {
            inset-block-start: 0;
            inset-inline-end: 0;
        }
    }
}

.pricing-section-4-wrapper {
    isolation: isolate;
    .pricing-section-4-top-img {
        inset-block-start: 0;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
} 