@use "sass:map";
@use "../bootstrap" as bs;
@mixin screen($key) {
	$size: map.get(bs.$grid-breakpoints, $key);
	@media (min-width: $size) {
		@content;
	}
}
//Mobile Landscape
@mixin landscape {
	@media all and (orientation: landscape) {
		@content;
	}
}

//Mobile Potrait
@mixin potrait {
	@media all and (orientation: potrait) {
		@content;
	}
}
