@use "../global" as *;

.pricing-section-5 {
	position: relative;
	isolation: isolate;
	.pricing-section-5-shape {
		position: absolute;
		z-index: -1;
		&-top {
			inset-block-start: 0;
			inset-inline-start: 50%;
			transform: translateX(-50%);
		}
		&-left {
			inset-block-start: 0;
			inset-inline-start: 0;
		}
		&-right {
			inset-block-start: 0;
			inset-inline-end: 0;
		}
	}
}

.pricing-5-list {
	position: relative;
	padding-block: rem(8);
	padding-inline: rem(12);
	border-radius: rem(12);
	border: 1px solid #48454e;
	background-color: #101415;
	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 500%;
		height: 1px;
		background: linear-gradient(#48454e, #101415);
	}
	&::before {
		left: calc(100% + 1rem);
	}
	&::after {
		right: calc(100% + 1rem);
	}
	li {
		&.active {
			button {
				color: #fff;
			}
		}
	}
}

.hidden {
	display: none;
}

.pricing-5-card {
	position: relative;
	padding-block: rem(25) rem(20);
	padding-inline: rem(40);
	background-color: #000;
	border: 1px solid #191c1d;
	border-radius: rem(24);
	transition: all 0.3s;
	z-index: 1;
	overflow: hidden;
	@include screen(lg) {
		padding-block: rem(40) rem(60);
	}
	&::before {
		position: absolute;
		content: "";
		inset-block-start: 0;
		inset-inline-start: 0;
		width: 100%;
		height: 182px;
		background-image: url("../img/pricing-5-card-line.png");
		z-index: -1;
		opacity: 0;
		transition: all 0.3s;
	}
	&:hover {
		background-color: rgba(86, 26, 255, 0.12);
		&::before {
			opacity: 1;
		}
		.pricing-5-card-name {
			color: hsl(var(--primary-key));
		}
		.pricing-5-card-price {
			color: #fff;
		}
		.pricing-5-card-btn {
			background-color: hsl(var(--primary-key));
			color: #fff;
			border: 1px solid hsl(var(--primary-key)) !important;
		}
		.list {
			li {
				span {
					border-color: #e1e3e3;
					color: #e1e3e3;
				}
			}
		}
	}
	&.popular {
		background-color: rgba(86, 26, 255, 0.12);
		&::before {
			opacity: 1;
		}
		.pricing-5-card-name {
			color: hsl(var(--primary-key));
		}
		.pricing-5-card-price {
			color: #fff;
		}
		.pricing-5-card-btn {
			background-color: hsl(var(--primary-key));
			color: #fff;
			border: 1px solid hsl(var(--primary-key)) !important;
		}
		.list {
			li {
				span {
					border-color: #e1e3e3;
					color: #e1e3e3;
				}
			}
		}
	}
	&-name {
		color: #c9c5d0;
		transition: all 0.3s;
	}
	&-price {
		font-size: rem(36);
		color: #79757f;
		transition: all 0.3s;
		.yearly-price,
		.lifetime-price,
		.prepaid-price {
			display: none;
		}
	}
	&-discount {
		border-radius: rem(4);
		background-color: rgba(145, 158, 171, 0.24);
		color: #c4c7c7;
		font-size: rem(10);
		font-weight: 600;
		padding: rem(2) rem(10);
	}
	&-btn {
		transition: all 0.3s;
	}
	.list {
		li {
			span {
				transition: all 0.3s;
			}
		}
	}
}
.popular-label {
	clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
}
