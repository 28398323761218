.dropdown-menu-light {
	--bs-dropdown-padding-x: 0.5rem;
	--bs-dropdown-item-padding-x: 0.5rem;
	--bs-dropdown-bg: hsl(var(--surface-container-low));
	--bs-dropdown-color: hsl(var(--on-surface));
	--bs-dropdown-border-color: hsl(var(--outline) / 0.15);
	--bs-dropdown-divider-bg: hsl(var(--outline) / 0.15);
	--bs-dropdown-link-color: hsl(var(--primary));
	--bs-dropdown-link-hover-bg: hsl(var(--surface-container-high));
	--bs-dropdown-link-hover-color: hsl(var(--on-surface));
	--bs-dropdown-link-active-color: hsl(var(--on-primary));
	--bs-dropdown-link-active-bg: hsl(var(--primary));
	.dropdown-item {
		--bs-dropdown-link-color: hsl(var(--on-surface));
	}
}
