@use "../global" as *;

.radar-logo-wrapper {
    position: relative;
    isolation: isolate;
    width: 300px;
    height: 300px;
    margin-inline: auto;
    z-index: 1;
    .radar {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 300px;
        border-radius: 50%;
        &::before {
            position: absolute;
            content: '';
            inset-block-start: 0;
            inset-inline-start: 0;
            background: linear-gradient(247deg, #5C2BFF -50.02%, rgba(92, 43, 255, 0.00) 54.9%);
            clip-path: polygon(0 0, 50% 50%, 100% 0);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: radar 5s infinite linear;
        }

        .animated-text-wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 9;
            margin-top: rem(38);
            .cd-headline {
                border-radius: 8px;
                background: rgba(86, 26, 255, 0.12);
                font-size: rem(12);
                font-weight: 700;
                color: hsl(var(--white));
                padding-block: rem(4);
                padding-inline: rem(16);
            }
        }
    }
    .logo-wrapper {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        width: 106px;
        height: 106px;
        &::before {
            position: absolute;
            content: '';
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            border-radius: 40px;
            background: #5C2BFF;
            z-index: -1;
        }
        &::after {
            position: absolute;
            content: '';
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            width: 72px;
            height: 72px;
            border-radius: 28px;
            background: linear-gradient(84deg, #8A62FB 4.85%, #00B8D9 95.15%);
            z-index: -1;
        }
        img {
            max-width: 40px;
            max-height: 30px;
        }
    }
}

.radar-main-wrapper {
    width: 600px;
    height: 600px;
    position: relative;
    .radar-main-circle-one {
        position: absolute;
        width: 100%;
        height: 100%;
        inset-block: 0;
        inset-inline: 0;
        border: 0.786px solid #211F24;
        background-color: #000;
        opacity: 0.5;
        z-index: -4;
        border-radius: 50%;
    }
    .radar-main-circle-two {
        position: absolute;
        width: calc(100% - 150px);
        height: calc(100% - 150px);
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        border: 0.786px solid #211F24;
        background-color: #000;
        opacity: 0.5;
        z-index: -3;
        border-radius: 50%;
    }
    .radar-main-circle-three {
        position: absolute;
        width: 407px;
        height: 407px;
        background: linear-gradient(180deg, #5C2BFF 0%, rgba(92, 43, 255, 0.00) 100%);
        filter: blur(200px);
        border-radius: 50%;
        inset-block-start: 60%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        z-index: -2;
    }
    .radar-logo-wrapper {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
    }
}