.shadow-1 {
	box-shadow: 0 1px 2px hsl(var(--shadow) / 0.16);
}
.shadow-2 {
	box-shadow: 0 4px 8px hsl(var(--shadow) / 0.16);
}
.shadow-3 {
	box-shadow: 0 8px 16px hsl(var(--shadow) / 0.16);
}
.shadow-4 {
	box-shadow: 0 12px 24px hsl(var(--shadow) / 0.16);
}
.shadow-5 {
	box-shadow: 0 16px 32px hsl(var(--shadow) / 0.16);
}
.shadow-6 {
	box-shadow: 0 20px 40px -4px hsl(var(--shadow) / 0.16);
}
.shadow-7 {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.2), 0 12px 24px -4px hsl(var(--shadow) / 0.12);
}
.shadow-8 {
	box-shadow: -40px 40px 80px -8px hsl(var(--shadow) / 0.24);
}
.shadow-9 {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.24), -20px 20px 40px -4px hsl(var(--shadow) / 0.24);
}
.shadow-10 {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.24), 20px 20px 40px -4px hsl(var(--shadow) / 0.24);
}

.\:shadow-1:hover {
	box-shadow: 0 1px 2px hsl(var(--shadow) / 0.16);
}
.\:shadow-2:hover {
	box-shadow: 0 4px 8px hsl(var(--shadow) / 0.16);
}
.\:shadow-3:hover {
	box-shadow: 0 8px 16px hsl(var(--shadow) / 0.16);
}
.\:shadow-4:hover {
	box-shadow: 0 12px 24px hsl(var(--shadow) / 0.16);
}
.\:shadow-5:hover {
	box-shadow: 0 16px 32px hsl(var(--shadow) / 0.16);
}
.\:shadow-6:hover {
	box-shadow: 0 20px 40px -4px hsl(var(--shadow) / 0.16);
}
.\:shadow-7:hover {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.2), 0 12px 24px -4px hsl(var(--shadow) / 0.12);
}
.\:shadow-8:hover {
	box-shadow: -40px 40px 80px -8px hsl(var(--shadow) / 0.24);
}
.\:shadow-9:hover {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.24), -20px 20px 40px -4px hsl(var(--shadow) / 0.24);
}
.\:shadow-10:hover {
	box-shadow: 0 0 2px hsl(var(--shadow) / 0.24), 20px 20px 40px -4px hsl(var(--shadow) / 0.24);
}
