@use "../global" as *;

.team-section {
    position: relative;
    z-index: 1;

    @include screen(xxl) {
        padding-block-start: rem(20);
    }
    .team-section-shape{
        position: absolute;
        z-index: -1;
        inset-block-end: 0;
        inset-inline-start: 0;
    }
}

.team-section-wrapper {
    position: relative;
    .team-section-line {
        position: absolute;
        inset-block-start: -68px;
        inset-inline-end: 0;
        display: none;
        @include screen(lg) {
            max-height: 120%;
            display: block;
        }
        @include screen(xl) {
            max-height: unset;
        }
    }
}

.team-card {
    border: 1px solid rgba(#48454E, 0.5);
    width: 265px;
}

.dark-overlay {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 48.35%, #000 100.91%);
    }
}