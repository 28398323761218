@use "../global" as *;

.custom-cursor {
	display: none;

	@include screen(lg) {
		display: block;
	}

	div {
		position: absolute;
		top: rem(300);
		left: rem(300);
		width: rem(32);
		height: rem(32);
		border: 1px solid hsl(var(--primary-key));
		border-radius: 50%;
		z-index: 9999;
		pointer-events: none;
		transform: translate(-50%, -50%);

		&:nth-child(2) {
			position: absolute;
			top: rem(300);
			left: rem(300);
			width: 0.75rem;
			height: 0.75rem;
			background-color: hsl(var(--primary-key));
			border-radius: 50%;
			z-index: 9999;
			pointer-events: none;
			transform: translate(-50%, -50%);
		}

		span {
			position: absolute;
			top: rem(44);
			left: rem(55);
			width: rem(300);
			font-size: rem(12);
			font-weight: 500;
			line-height: 1;
			color: hsl(var(--white));
			opacity: 0;
			transition: opacity 0.5s;
			z-index: 1;

			&.visible {
				opacity: 1;
			}
		}
	}

	&__img {
		position: absolute;
		top: rem(20);
		left: rem(32);
		opacity: 0;
		transition: opacity 0.5s;

		&.visible {
			opacity: 1;
		}
	}
}