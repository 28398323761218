@use "../global" as *;

.menu {
	position: relative;
	isolation: isolate;

	&-nav {
		display: flex;
		position: absolute;
		inset-inline: 0;
		inset-block-start: calc(100% - 0.5rem);
		background-color: hsl(var(--inverse-surface));
		box-shadow: 0 0.5rem 1rem hsl(var(--black) / 0.05);
		transition: all 0.3s ease;
		z-index: -1;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;

		@include screen(lg) {
			background-color: transparent;
			position: relative;
			z-index: 1;
			visibility: visible;
			opacity: 1;
			pointer-events: unset;
			box-shadow: none;
			ul + ul {
				li + li {
					position: relative;
					isolation: isolate;
					&::before {
						content: "";
						width: 1px;
						height: 1.5rem;
						background-color: hsl(var(--outline) / 0.25);
						position: absolute;
						inset-block-start: 50%;
						inset-inline-start: -0.75rem;
						transform: translateY(-50%);
					}
				}
			}
		}

		.has-sub {
			justify-content: space-between;
			gap: 0.25rem;

			@include screen(lg) {
				gap: 0.5rem;
			}

			&::after {
				content: "\f282";
				display: inline-block;
				font-family: bootstrap-icons !important;
				font-size: 12px;
				font-style: normal;
				font-weight: 400 !important;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				vertical-align: -0.125em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			&.clear-content {
				&::after {
					display: none;
				}
			}
		}
	}

	&-open {
		.menu-nav {
			z-index: 1;
			visibility: visible;
			opacity: 1;
			pointer-events: unset;
			inset-block-start: 100%;
		}
	}

	&-list {
		@include screen(lg) {
			position: relative;
			isolation: isolate;
		}

		& + .menu-list {
			.menu-link {
				border-top: 1px solid hsl(var(--outline) / 0.15);

				@include screen(lg) {
					border-top: none;
				}
			}
		}

		&:hover {
			@include screen(lg) {
				.menu-link {
					color: hsl(var(--primary-2));
				}

				.menu-sub {
					inset-block-start: 100%;
					pointer-events: unset;
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&.current-page {
			> .menu-link {
				color: hsl(var(--primary-container));
				@include screen(lg) {
					color: hsl(var(--primary-2));
				}
			}
		}
		&:last-child {
			.menu-sub {
				@include screen(lg) {
					inset-inline-start: auto;
					inset-inline-end: 0;
				}
			}
		}
	}

	&-link {
		display: flex;
		align-items: center;
		padding: 0.5rem 1rem;
		position: relative;
		isolation: isolate;
		font-size: 0.875rem;
		color: hsl(var(--white));

		@include screen(lg) {
			padding: 1.75rem 0.75rem;
			font-size: 1rem;
			color: hsl(var(--on-background));
		}

		&.active {
			color: hsl(var(--primary-container));
		}
	}

	&-sub {
		transition: height 0.3s ease-in-out;
		overflow: hidden;

		@include screen(lg) {
			min-width: 180px;
			position: absolute;
			inset-block-start: calc(100% + 0.5rem);
			inset-inline-end: 0;
			background-color: hsl(var(--neutral-99));
			box-shadow: 0 0.5rem 1rem hsl(var(--shadow) / 0.25);
			border-radius: 0.25rem;
			pointer-events: none;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
		@include screen(xl) {
			inset-inline-start: 0;
			min-width: 200px;
		}

		&:not(.active) {
			display: none;

			@include screen(lg) {
				display: block;
			}
		}

		&-link {
			display: flex;
			align-items: center;
			padding: 0.5rem 1rem 0.5rem 2rem;
			position: relative;
			isolation: isolate;
			font-size: 0.875rem;
			color: hsl(var(--white));
			transition: all 0.3s ease-in-out;

			@include screen(lg) {
				padding: 0.75rem 1rem;
				font-size: rem(14);
				color: hsl(var(--black));

				&:hover {
					background-color: hsl(var(--neutral-95));
					color: hsl(var(--black));
				}
			}
		}
		&-list + .menu-sub-list {
			.menu-sub-link {
				border-top: 1px solid hsl(var(--outline) / 0.1);
			}
		}
	}
}
