@use "../global" as *;
.about-ai-slider {
	width: 100%;
	height: 350px;
	@include screen(md) {
		height: 450px;
	}
	@include screen(xl) {
		height: 460px;
	}
	.swiper-scrollbar {
		--swiper-scrollbar-bg-color: hsl(var(--neutral-10));
		--swiper-scrollbar-sides-offset: 10%;
	}
	.swiper-scrollbar-drag {
		--swiper-scrollbar-drag-bg-color: hsl(var(--primary-key));
	}
	.swiper-pagination-fraction {
		--swiper-pagination-top: 0;
		--swiper-pagination-bottom: 0;
		color: hsl(var(--white));
		z-index: -1;
		pointer-events: none;
	}
	.swiper-pagination-current {
		opacity: 1;
		position: absolute;
		right: 0;
	}
	.swiper-pagination-total {
		opacity: 1;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}
