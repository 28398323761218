@use "../global" as *;
.circle-btn {
	display: grid;
	place-content: center;
	width: 150px;
	aspect-ratio: 1;
	border-radius: 50%;
	color: #fff;
	text-decoration: none;
	&.welcome-img-btn {
		position: absolute;
		right: 1.5rem;
		bottom: 1.5rem;
		@include screen(xl) {
			right: 0;
			bottom: 0;
			transform: translate(50%, 50%);
		}
	}
	&__text {
		position: absolute;
		inset: 0;
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		animation: animateText 18s linear infinite;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 50px;
			aspect-ratio: 1;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background: linear-gradient(0deg, #6a41fb 0%, #6a41fb 100%);
			z-index: -1;
		}
		span {
			position: absolute;
			top: 0;
			text-transform: uppercase;
			display: inline-block;
			transform-origin: 0 75px;
		}
	}
}

