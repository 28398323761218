.pill-tabs {
	.nav-link {
		position: relative;
		isolation: isolate;
		transition: all 0.3s;
		&::before {
			position: absolute;
			content: "";
			inset: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(270deg, #4d89f9 20%, #704ee7 40%, #e8428c 80%, #ed6d4b 100%);
			background-size: 150%;
			transition: all 0.4s ease-in-out;
			background-position: left;
			color: hsl(var(--white));
			opacity: 0;
			z-index: -1;
			border-radius: 0.5rem;
		}
		&.active {
			color: hsl(var(--white));
			&:hover {
				&::before {
					background-position: right;
					transition: all 0.4s ease-in-out;
				}
			}
			&::before {
				opacity: 1;
			}
		}
	}

	&-style-two {
		.nav-link {
			&::before {
				display: none;
			}
			color: hsl(var(--white));
			&.active {
				background-color: hsl(var(--white));
				color: hsl(var(--primary));
				i {
					display: inline-block;
				}
			}
			i {
				margin-inline-end: 3px;
				display: none;
			}
		}
	}
}
