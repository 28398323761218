@use "../global" as *;
.ff-1 {
	font-family: var(--ff-1);
}
.ff-2 {
	font-family: var(--ff-2);
}
.ff-3 {
	font-family: var(--ff-3);
}
.ff-4 {
	font-family: var(--ff-4);
}

.active-clr {
	color: hsl(var(--primary)) !important;
}

.active-bg {
	background-color: hsl(var(--primary)) !important;
}

.cursor-pointer {
	cursor: pointer;
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: var(--bs-border-color);
	}
}

.form-select {
	&:focus {
		box-shadow: none;
		border-color: var(--bs-border-color);
	}
}

.container-fixed {
	@include screen(xmax) {
		max-width: 1920px;
	}
}

.link {
	text-decoration: none;
	transition: all 0.3s ease;
}
.mw-unset {
	min-width: unset;
}
.overflow-x-auto {
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}

.overflow-y-auto {
	scrollbar-color: transparent transparent;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}

.inset-0 {
	inset: 0;
}

.pointer-none {
	pointer-events: none;
}

.pointer {
	cursor: pointer;
}

.transition {
	transition: all 0.3s ease;
}

.place-content-center {
	place-content: center;
}

.place-items-center {
	place-items: center;
}

.top-shadow {
	position: relative;
	isolation: isolate;
	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border: 1px solid transparent;
		border-radius: inherit;
		background: linear-gradient(hsl(var(--neutral-30)), transparent) border-box;
		-webkit-mask: linear-gradient(hsl(var(--black)) 0 0) padding-box, linear-gradient(hsl(var(--black)) 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		z-index: -1;
	}
}

.position-center {
	inset-inline-start: 50%;
	inset-block-start: 50%;
	transform: translate(-50%, -50%);
}

.position-center-y {
	inset-block-start: 50%;
	transform: translateY(-50%);
}

.translate-y-100 {
	transform: translateY(100%);
}
.translate-y-n100 {
	transform: translateY(-100%);
}
.translate-y-50 {
	transform: translateY(50%);
}
.translate-y-n50 {
	transform: translateY(-50%);
}
.translate-y-25 {
	transform: translateY(25%);
}
.translate-y-n25 {
	transform: translateY(-25%);
}

.\:focus-outline-0 {
	&:focus {
		outline: none;
	}
}

.yearly-price {
	display: none;
}

.text-indent {
	text-indent: 2.5rem;
}
.btn-outlined {
	outline: 4px solid #e5deff;
}
.blurred-post {
	position: relative;
	isolation: isolate;
	overflow: hidden;
	background-color: #101415;
	&::after {
		content: url(../img/blur-blog-shape.png);
		width: 100%;
		height: 100%;
		position: absolute;
		inset: 0;
		z-index: -1;
		pointer-events: none;
	}
}
.post-2-bg {
	background-image: url(../img/post-2-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.post-3-bg {
	background-image: url(../img/post-3-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.title-star {
	@include screen(xl) {
		position: relative;
		&::before {
			content: url(../img/star.png);
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
			pointer-events: none;
		}
		&::after {
			content: url(../img/title-line.png);
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 50%;
			transform: translate(-50%, -25%);
			z-index: -1;
			pointer-events: none;
		}
	}
}
.add-0 {
	list-style-type: decimal-leading-zero;
}

.placeholder-50 {
	&::placeholder {
		color: hsl(var(--neutral-50));
	}
}

.focus-bg-none {
	&:focus {
		background: transparent;
		border: 1px solid hsl(var(--neutral-30)) !important;
	}
}

.ai-card-btn {
	padding: 0;
	border: 0;
	line-height: 1;
	width: 1.75rem;
	height: 1.75rem;
	display: grid;
	place-content: center;
	border-radius: 50%;
	font-size: 14px;
	line-height: 1;
	color: hsl(var(--neutral-70));
	transition: all 0.3s ease;
	&:hover {
		color: hsl(var(--primary-50));
		background-color: hsl(var(--primary-90));
	}
}
