.gradient-card {
	position: relative;
	isolation: isolate;
	background: linear-gradient(180deg, #0b0f0f 68.54%, rgba(11, 15, 15, 0) 86.92%);
	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border: 1px solid transparent;
		border-radius: inherit;
		background: linear-gradient(hsl(var(--neutral-30)), transparent) border-box;
		-webkit-mask: linear-gradient(hsl(var(--black)) 0 0) padding-box, linear-gradient(hsl(var(--black)) 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		z-index: -1;
	}
}
