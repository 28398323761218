@use "functions" as *;

html {
	font-size: 100%;
}

body {
	position: relative;
	background-color: hsl(var(--background));
	overflow-x: clip;
	font-family: var(--ff-3);
	font-size: rem(16);
	font-weight: 400;
	line-height: 1.5;
	color: hsl(var(--on-background));
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

form {
	margin: 0;
}
