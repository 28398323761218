@use "../global" as *;

.how-work-section {
    .section-top-bg-shape {
        inset-block-start: -16%;
    }
}

.how-work-wrapper {
    margin: -30px 0;
}

.how-work-single-row {
    position: relative;
    padding: rem(30) 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    justify-content: center;
    text-align: center;
    @include screen(lg) {
        text-align: start;
        justify-content: space-between;
        &::after {
            position: absolute;
            content: '';
            inset-block-end: -30px;
            inset-inline-start: 0;
            height: 60px;
            width: 100%;
            background-image: url('../img/how-work-flow-line-1.png');
            background-position-x: center;
            background-repeat: no-repeat;
            z-index: -1;
        }
        &:nth-child(odd) {
            align-items: flex-end;
            .content {
                padding-inline: 30px;
            }
        }
        &:nth-child(even) {
            align-items: flex-start;
            &::after {
                background-image: url('../img/how-work-flow-line-2.png');
            }
            .content {
                order: -1;
            }
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }
    
    @include screen(xl) {
        &:nth-child(odd) {
            align-items: start;
        }
        &:nth-child(even) {
            align-items: start;
        }
    }
    .thumb {
        @include screen(xl) {
            width: calc((100% / 12) * 5);
        }
        @include screen(lg) {
            width: 50%;
        }
        
        img {
            @include screen(sm) {
                max-height: 300px;
            }
            @include screen(lg) {
                max-height: 100%;
            }
        }
    }
    .content {
        @include screen(xl) {
            width: calc((100% / 12) * 6);
        }
        @include screen(lg) {
            width: 50%;
            margin-block-start: 0;
        }
        @include screen(xs) {
            margin-block-start: 30px;
        }
    }
}

.how-work-shape {
    position: absolute;
    z-index: -1;
    &-left {
        inset-block-start: 0;
        inset-inline-start: 0;
    }
    &-right {
        inset-block-start: 0;
        inset-inline-end: 0;
    }
}