.tt-scroll-top {
	width: 65px;
	height: 65px;
	line-height: 65px;
	position: fixed;
	top: 100%;
	right: 50px;
	font-size: 16px;
	border-radius: 4px;
	z-index: 99;
	text-align: center;
	cursor: pointer;
	-webkit-transition: 1s ease;
	transition: 1s ease;
	border: none;
	&::after {
		position: absolute;
		z-index: -1;
		content: "";
		top: 115%;
		left: 5%;
		height: 25px;
		width: 90%;
		opacity: 1;
		background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
	}
	&.open {
		top: 85%;
	}
}
